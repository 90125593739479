import api from '@/libs/axios'

export default {
  async getList(params) {
    let response = await api.get('/admin/ai-banners', {
      params,
    })
    return response
  },

  async get(params) {
    let response = await api.get('/admin/ai-banners/detail', {
      params,
    })
    return response.data.data.document || null
  },

  async create(payload) {
    const response = await api.post('/admin/ai-banners', payload)
    return response || null
  },

  async update(payload) {
    const response = await api.put('/admin/ai-banners', payload)
    return response || null
  },

  async delete(payload) {
    const response = await api.delete('/admin/ai-banners', {
      data: payload,
    })
    return response
  },
}
